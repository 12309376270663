import React, {Component} from 'react';
import moment from 'moment';
import {compose} from "redux";
import {connect} from "react-redux";
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {loadOrders} from "./ChargingHistoryData";
import * as colors from '../../util/colors';

import {Column, InfiniteLoader, Table} from 'react-virtualized';
import Avatar from '../common/Avatar';
import Paper from '../common/Paper';
import SearchForm from "../common/Form/SearchForm";
import {connectorType, Data} from "./ChargingHistoryColumns";
import MiddleBar from "../Location/MiddleBar";
import LoadingSpinner from "../common/LoadingSpinner";
import RefreshIcon from "../icons/RefreshIcon";
import Button from "../common/Button";

class ChargingHistoryContainer extends Component {
  offset = 0;
  loadMoreIncrement = 50;
  from = moment().subtract(1, 'months').toISOString();
  to = moment().toISOString();

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      search: '',
      loadingMore: false,
      lastLoad: true,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.loadOrders();
  }

  loadMore = async () => {
    await this.loadOrders(true);
  }

  onSearchChange = (authorization, { search = '' } = {}) => {
    this.setState({
      search,
      orders: [],
    }, () => {
      this.loadOrders();
    });
  }

  loadOrders = async (loadMore) => {
    if (!loadMore) {
      this.offset = 0;
    }

    this.setState({
      loadingMore: true,
    })

    const orders = await loadOrders({
      from: this.from,
      to: this.to,
      search: this.state.search,
      limit: this.loadMoreIncrement,
      offset: loadMore ? this.offset : 0,
      authorization: this.props.authorization,
    });

    this.offset += this.loadMoreIncrement;

    const scrollToBottom = () => {
      const gridScroll = this.containerRef.current.querySelector('[aria-label=grid]');

      gridScroll.scrollTop = gridScroll.scrollHeight;
    }

    this.setState({
      orders: loadMore ? [...this.state.orders, ...orders] : orders,
      loadingMore: false,
      lastLoad: orders.length !== this.loadMoreIncrement,
    }, loadMore && scrollToBottom);
  };

  onRefreshClick = () => {
    this.loadOrders();
  }

  rowGetter = (value) => this.state.orders[value.index];

  renderLocation = (location) => {
    if (!location) {
      return (<Loading width={this.randomWidth}/>);
    }

    const locationTitle = location.street === undefined
        ? 'Loading...'
        : `${location.street}, ${location.city}, ${location.country}`;
    return (
      <Data>
        <Location>
          <div className="name">{location.name}</div>
          {this.props.isAdministrator
              ? <a className="address" href={`/locations/${encodeURIComponent(location.id)}/stations`}>{locationTitle}</a>
              : <a className="address">{locationTitle}</a>
          }
        </Location>
      </Data>
  )};

  renderStation = (connector, station) => {
    return station ? (
      <Data>
        <Station>
          {station && station.number && (this.props.isAdministrator || this.props.isManager || this.props.isOperator
              ? <a className="station" href={`/charge-points/${encodeURIComponent(station.id)}/information`}>{station.id}</a>
              : <a className="station">{station.id}</a>
          )}
        </Station>
      </Data>
    ) : (
      <Loading width={this.randomWidth}/>
    );
  }

  renderChargingTime = (rowData) => {
    return (
      <Data>
        <div>
          <Datetime>
            <div className="time">
              {timestampToString(rowData.charging_start || rowData.reservation_start)[0]}
              {' - '}
              {timestampToString(rowData.charging_finish || rowData.reservation_finish)[0]}
            </div>
            <div className="date row">
              {timestampToString(rowData.reservation_start)[1]}
            </div>
          </Datetime>
        </div>
      </Data>
    );
  }

  price = price => <Data><Price value={price}>{price / 100}₽</Price></Data>;

  capturedAmount = (amount, rowData) => {
    const charging = rowData.charging_price;
    const status = rowData.status;
    const meter_stop = rowData.meter_stop;
    const meter_start = rowData.meter_start;
    const reservation = rowData.reservation_price;
    let isPriceWrong;
    if (status === 'completed') {
      isPriceWrong = (charging + reservation) !== amount && ((charging + reservation) > 0 || amount > 0);
    } else {
      isPriceWrong = +meter_stop > +meter_start;
    }

    if (reservation > 0) {
      return (
          <Parking value={amount}>
            <div className="name"><ParkingPrice isWrong={isPriceWrong} value={amount}>{(amount - reservation) / 100}₽</ParkingPrice></div>
            <div className="name"><ParkingPrice isWrong={isPriceWrong} value={amount}>+{reservation / 100} бронь</ParkingPrice></div>
          </Parking>
      );
    } else {
      return (
          <Data><Price isWrong={isPriceWrong} value={amount}>{amount / 100}₽</Price></Data>
      );
    }
  }

  capturedParkingAmount = rowData => {
    if (!rowData) { return '';}
    if (rowData.ev_connected) {
      return (<Parking value='1'><div className="name">подкл.</div></Parking>);
    }
    const time = rowData.parking_time;
    if (time === null) {
      return (<Parking value='0'><div className="name"> &nbsp; </div></Parking>);
    }
    const amount = rowData.parking_amount;
    const captured_amount = rowData.parking_captured_amount;
    const isPriceWrong = amount && amount !== captured_amount;
    const min = Math.floor(time / 60);
    if (min < 1 && captured_amount < 100) {
      return (<Parking value={amount}><div className="name">{captured_amount / 100}</div></Parking>);
    }
    return (
        <Parking value={amount}>
          <div className="name"><ParkingPrice isWrong={isPriceWrong} value={captured_amount}>{captured_amount / 100}₽</ParkingPrice></div>
          <a>{min > 0 ? `(${min} мин)` : ''}</a>
        </Parking>
    );
  }

  pdf = () => <Data width='100px'><PdfButton>PDF <i className="fa fa-arrow-down" /></PdfButton></Data>;

  kwh = rowData => (<Data>
    <div>{parseFloat(((rowData.meter_stop - rowData.meter_start) / 1000).toFixed(3))}</div>
  </Data>);

  status = status => <Data><Status value={status}>{status}</Status></Data>

  name = (user, rfid) => user ? (
    <Data>
      <Avatar
        size="32px"
        rounded
        style={{ marginRight: '8px' }}
        imageSrc={(user && user.avatar_url) ? user.avatar_url : null}
      />
      <Location>
        <div className="name">{user.phone || 'Unknown'}{rfid ? (<Rfid>RFID</Rfid>) : ''}</div>
        <div className="address">
          {user && user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : 'Unknown'}
        </div>
      </Location>
    </Data>
  ) : (
    <Loading width={this.randomWidth}/>
  );

  rowLoaded = index => !!this.state.orders[index];

  // Max: 70. Min: 35.
  randomWidth = () => [(Math.floor(Math.random() * (70 - 35 + 1)) + 35), 'px'].join('');

  render() {
    const {props} = this;
    const {t} = props;
    const {loadingMore, lastLoad} = this.state;

    return (
      <Paper padding="0">
        <Container ref={this.containerRef}>
          <Header>
            <Title>{t('chargingHistory')}</Title>
            <RightSide>
              <SearchForm onChange={this.onSearchChange} />

              <Button
                theme="blue"
                minWidth="none"
                onClick={this.onRefreshClick}
                style={{ marginRight: 20 }}
              >
                <RefreshIcon />
              </Button>
            </RightSide>
          </Header>
          {/* <Searchbar /> */}
          <InfiniteLoader
            isRowLoaded={this.rowLoaded}
            rowCount={0}
            threshold={10}
            loadMoreRows={()=>{}}
          >
            {({onRowsRendered, registerChild}) => (
              <Table
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowHeight={52}
                headerHeight={52}
                width={1400}
                height={500}
                rowClassName="ordersRow"
                rowGetter={this.rowGetter}
                rowCount={this.state.orders.length}
              >
                <Column width={250}
                  dataKey='user_id'
                  label={t('client')}
                  style={{paddingLeft: '24px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => this.name(rowData.user, rowData.rfid)}
                />
                <Column width={120}
                  dataKey='status'
                  label={t('status')}
                  style={{paddingLeft: '14px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({cellData}) => this.status(cellData)}
                />
                <Column width={330}
                  dataKey='location'
                  label={t('locations')}
                  style={{paddingLeft: '12px',paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => this.renderLocation(rowData.location)}
                />
                <Column width={150}
                  dataKey='evse_number'
                  label={t('station')}
                  style={{paddingLeft: '12px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => this.renderStation(rowData.connector, rowData.station)}
                />
                <Column width={150}
                  dataKey='connector_id'
                  label={t('connectorType')}
                  style={{paddingLeft: '26px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => connectorType(rowData.connector)}
                />
                <Column width={170}
                  dataKey='charging_start'
                  label={t('chargingTime')}
                  style={{paddingLeft: '12px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => this.renderChargingTime(rowData)}
                />

                <Column width={110}
                        dataKey='parking_amount'
                        label={!this.props.isAdministrator ? '' : 'Простой'}
                        style={{paddingLeft: '12px'}}
                        headerClassName="headerOrders"
                        cellRenderer={({rowData}) => (!this.props.isAdministrator
                            ? (<i/>)
                            : this.capturedParkingAmount(rowData))}
                />

                <Column width={80}
                  dataKey='meter_stop'
                  label={t('kWh')}
                  style={{paddingLeft: '12px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => this.kwh(rowData)}
                />
                <Column width={150}
                    dataKey='captured_amount'
                    label={t('chargingRevenue')}
                    style={{paddingLeft: '12px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({cellData, rowData}) => this.capturedAmount(cellData, rowData)}
                />

                <Column width={35}
                        dataKey='order_details'
                        label=''
                        style={{paddingLeft: '1px'}}
                        headerClassName="headerOrders"
                        cellRenderer={({rowData}) => (!this.props.isAdministrator && !this.props.isOperator
                                ? (<i/>)
                                : (<a
                                    href={`/orders/${encodeURIComponent(rowData.id)}/information`}
                                    style={{color: '#212529'}}
                                    title={'Детали заказа'}
                                    className="fa fa-list"
                                />)
                        )}
                />
              </Table>
            )}
          </InfiniteLoader>

          {loadingMore && (
            <LoadingSpinner/>
          )}

          {!loadingMore && !lastLoad && (
            <MiddleBar
              buttonText="Подгрузить еще 50"
              buttonOnClick={this.loadMore}
            />
          )}

        </Container>
      </Paper>
    );
  }
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  overflow-x: auto;
`;

export const Title = styled.h2`
  margin: 24px;
  font-size: 20px;
  font-weight: bold;
`;

const Status = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 24px; max-height: 24px;
  padding: 0 12px;
  border: 1px solid transparent; border-radius: 18px;
  font-size: 0.75em;
  font-weight: bold;
  color: white;
  background-color: ${props => statusColorByValue(props.value)};
`;

export const Location = styled.div`
  font-size: 0.875em;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  & > div.name {
    min-width: 250px; max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
  & > .address {
    min-width: 250px; max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > a.address {
    color: ${colors.TEXT_MINOR};
    &:hover {
      background-color: #F3F5F6;
    }
  }
`;

export const Price = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: ${props => {
    if (props.isWrong) return colors.ERROR;
    return +props.value === 0 ? 'black' : colors.ICON_GREEN;
  }}
`;

export const Parking = styled.div`
  font-size: 0.875em;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  color: ${props => +props.value === 0 ? 'lightgray' : 'inherit'};
  
  & > div.name {
    font-weight: bold;
  }
`;

export const ParkingPrice = styled.span`
  color: ${props => {
    if (props.isWrong) return colors.ERROR;
    return +props.value === 0 ? 'inherit' : colors.ICON_GREEN;
  }}
`;

const Station = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 5px;
  & a.station {
    color: ${colors.TEXT_MINOR};
    font-size: smaller;
    min-width: 100%;
    &:hover {
      background-color: #F3F5F6;
    }
  }
`;

export const Loading = styled.div`
  min-width: ${props => props.width || '15px'};
  width: ${props => props.width || '15px'};
  height: 20px;
  display: block;
  background-color: #DDD;
`;

const Datetime = styled.div`
  & > div.time {
    font-weight: bold;
    min-width: 100%;
    margin-left: 0;
    white-space: nowrap;
  }
  & > div.date {
    color: ${colors.TEXT_MINOR};
    min-width: 100%;
    margin-left: 0;
    white-space: nowrap;
  }
`;

export const statusColorByValue = status => {
  switch (status) {
    case 'canceled':
    case 'expired':
      return colors.ERROR;
    case 'completed':
      return colors.ICON_GREEN;
    case 'started':
      return colors.ICON_BLUE;
    case 'active':
      return colors.ICON_LIGHT_GREEN;
    case 'countdown':
      return colors.ICON_ORANGE;
    case 'starting':
      return colors.ICON_LIGHT_BLUE;
    case 'wait_payment':
      return colors.ICON_ORANGE;
    default:
      return colors.ERROR;
  }
};

// TODO Code reusability.
export const connectorImageByType = connectorType => {
  if (!connectorType || typeof connectorType !== 'string') {
    return '/connectors/type1.svg';
  }
  switch (connectorType.toLowerCase()) {
    case 'type 1':
      return '/connectors/type1.svg';
    case 'type 2':
      return '/connectors/type2.svg';
    case 'ccs':
      return '/connectors/ccs.svg';
    case 'schuko':
      return '/connectors/schuko.svg';
    case 'chademo':
      return '/connectors/chademo.svg';
    case '3-pin':
      return '/connectors/3-pin.svg';
    case 'ccs 1':
      return '/connectors/ccs1.svg';
    case 'ccs 2':
      return '/connectors/ccs2.svg';
    case 'gb/t ac':
      return '/connectors/gbt-ac.svg';
    case 'gb/t dc':
      return '/connectors/gbt-dc.svg';
    case 'tesla':
      return '/connectors/tesla.svg';
    default:
      return '/connectors/type1.svg';
  }
};

const PdfButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 72px; max-width: 72px;
  min-height: 24px; max-height: 24px;
  padding: 0 12px;
  border: 1px solid transparent; border-radius: 36px;
  font-size: 12px;
  font-weight: bold;
  color: ${colors.BLUE_MAIN};
  background-color: ${colors.BLUE_LIGHT};
  cursor: not-allowed;
  & > i.fa {
    margin-left: 6px;
  }
  /**/display: none;
`;
PdfButton.defaultProps = { disabled: true };

const Rfid = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    min-width: 35px;
    height: 13px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    padding: 1px;
    background-color: red;
`;

const timestampToString = timestamp => {
  const date = new Date(timestamp);
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = [
    'January', 'February',
    'March', 'April', 'May',
    'June', 'July', 'August',
    'September', 'October', 'November',
    'December'
  ][monthIndex];
  const year = 1900 + date.getYear();

  if (hours.length !== 2) {
    hours = `0${hours}`;
  }
  if (minutes.length !== 2) {
    minutes = `0${minutes}`;
  }
  return [`${hours}:${minutes}`, `${day} ${month} ${year}`];
};

const mapStateToProps = ({ manager }) => ({
  isAdministrator: manager.data?.role === 'administrator',
  isManager: manager.data?.role === 'manager',
  isOperator: manager.data?.role === 'operator',
});

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  withRouter
)(ChargingHistoryContainer);
