import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import {geocode} from '../../../api';
import LoadingSpinner from '../../common/LoadingSpinner';
import Form, {Title, TextField} from '../../common/Form';
import {Close} from '../../common/Modal';
import OpeningHoursContainer from './OpeningHoursContainer';
import LocationOwnerSelect from "../LocationOwnerSelect";

class LocationSettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: {value: '', valid: undefined},
        country: {value: '', valid: undefined},
        city: {value: '', valid: undefined},
        street: {value: '', valid: undefined},
        postal_code: {value: '', valid: undefined},
        owner_id: {value: '', valid: undefined},
      },
    };
  }

  componentWillMount() {
    const {locationId, data, getLocation} = this.props;

    getLocation(locationId, () => {
      // storing data to state
      this.setState({
        data: {
          name: {value: data.name, valid: true},
          country: {value: data.country, valid: true},
          city: {value: data.city, valid: true},
          street: {value: data.street, valid: true},
          postal_code: {value: data.postal_code, valid: true},
          owner_id: {value: data.owner_id, valid: true},
        },
        address: this.getAddress(data),
      });
    });
  }

  handleSubmit = async (event, callback) => {
    event.preventDefault();

    const {data} = this.state;
    const formData = {};

    for (let fieldName in data) {
      formData[fieldName] = data[fieldName].value;
    }

    const address = this.getAddress(formData);

    if (address !== this.state.address) {
      formData.coordinates = await this.getCoordinates(address);
    }

    this.updateLocation(formData, callback);
  };

  getCoordinates = (address) => {
    return geocode(this.props.authorization, {address})
      .then(response => response.location);
  }

  updateLocation = (formData, callback) => {
    const {authorization, locationId} = this.props;

    this.props.updateLocation(authorization, locationId, formData, () => {
      if (callback) { callback(); }
    });
  };

  getAddress = data => {
    return [data.country, data.city, data.street].join();
  };

  handleChange = event => {
    const {name, value} = event.target;

    this.setFieldData(name, value);
  };

  setFieldData = (name, value) => {
    const data = {...this.state.data};

    data[name].value = value;
    data[name].valid = this.fieldIsValid(name, value);

    this.setState({data});
  };

  fieldIsValid = (name) => {
    switch (name) {
      // TODO: name cases
      default:
        return true;
    }
  };

  render() {
    const {loading, error, authorization, locationId,
      getLocation, setLocationOpeningHours, history, t} = this.props;
    const {data} = this.state;

    const allFieldsValid = Object.values(this.state.data)
      .filter(field => !field.valid).length === 0;

    if (loading) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      );
    }

    return (
      <Container>
        <Close onClick={() => history.push(`/locations/${locationId}/stations`)}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Form>
          <Title>{t('locationSettings')}</Title>

          <FieldContainer>
            <TextField
              name="name" value={data.name.value} valid={data.name.valid}
              label={t('name')} required warning="This field is required"
              handleChange={this.handleChange}
              width="calc(25% - 12px)" margin="0 12px 0 0"
            />
            <LocationOwnerSelect
              width="calc(25% - 12px)"
              value={this.state.data.owner_id.value}
              handleChange={this.handleChange}
            />
          </FieldContainer>
          <FieldContainer>
            <TextField
              name="country" value={data.country.value} valid={data.country.valid}
              label={t('country')} required warning="This field is required"
              handleChange={this.handleChange}
              width="calc(25% - 12px)" margin="0 12px 0 0"
            />
            <TextField
              name="city" value={data.city.value} valid={data.city.valid}
              label={t('city')} required warning="This field is required"
              handleChange={this.handleChange}
              width="calc(25% - 12px)" margin="0 12px 0 0"
            />
            <TextField
              name="street" value={data.street.value} valid={data.street.valid}
              label={t('street')} required warning="This field is required"
              handleChange={this.handleChange}
              width="calc(25% - 12px)" margin="0 12px 0 0"
            />
            <TextField
              name="postal_code" value={data.postal_code.value}
              label={t('postalCode')} warning="This field is required" /* FIXME */
              handleChange={this.handleChange}
              width="calc(25% - 12px)" margin="0 12px 0 0"
            />
          </FieldContainer>

          {this.props.data.opening_hours &&
          <OpeningHoursContainer
            data={this.props.data.opening_hours}
            allFieldsValid={allFieldsValid}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            authorization={authorization}
            locationId={locationId}
            getLocation={getLocation}
            setLocationOpeningHours={setLocationOpeningHours}
            error={error}
          />}
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 32px 40px;
  padding: 0;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-start;
`;

export default compose(withTranslation(), withRouter)(LocationSettingsForm);
