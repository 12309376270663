import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getLocations from '../../redux/actions/getLocations';
import SearchForm from "../common/Form/SearchForm";

function LocationsSearchForm({getLocations}) {
  return (
    <SearchForm onChange={getLocations}/>
  )
}

const mapDispatchToProps = dispatch => ({
  getLocations: (authorization, params) => dispatch(getLocations(authorization, params)),
});

export default connect(null, mapDispatchToProps)(compose(withTranslation(), withRouter)(LocationsSearchForm));
