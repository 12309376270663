import React from 'react';
import {connect} from 'react-redux';
import getStations from "../../redux/actions/getStations";
import Button from "../common/Button";
import RefreshIcon from "../icons/RefreshIcon";

function RefreshButton({getStations, authorization, params }) {
  const onClick = () => {
    getStations(
      authorization,
      {
        mode: 'full',
        limit: 100,
        search: params?.search || '',
      },
    );
  }

  return (
    <Button
      theme="blue"
      minWidth="none"
      onClick={onClick}
    >
      <RefreshIcon />
    </Button>
  )
}

const mapStateToProps = ({manager, stations: {params}}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  params,
});

const mapDispatchToProps = dispatch => ({
  getStations: (authorization, params) => dispatch(getStations(authorization, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefreshButton);
