import React, {Component} from 'react';
import styled from 'styled-components';

import bp from '../breakpoints';
import * as colors from '../../../util/colors';

const optionItems = (options, name, handleSelect, activeLangId, noDot, optionHoverColor, optionStyle, fontSize) => {
  return options.map((option, i) => (
    <Option
      hoverColor={optionHoverColor}
      style={{...optionStyle}}
      key={i}
      name={name}
      onMouseDown={(e) => handleSelect(name, option.id)} // REVIEW
      onClick={(e) => handleSelect(name, option.id)}     // REVIEW
    >
      {option.icon && <Icon src={option.icon} alt=''/>}
      <span style={{paddingRight: '5px', fontSize: fontSize || '1em'}}>{option.useShorthand ? option.shorthand.toUpperCase() : option.name}</span>
      {!noDot && (activeLangId === option.id) && <img src='/blueDot.svg' alt='Blue dot' visible={(activeLangId === option.id) ? 'block' : 'none'}/>}
    </Option>
  ));
};

class SelectField extends Component {
  state = {
    optionsVisible: false,
  };

  handleOptionsToggle = event => {
    this.setState({ optionsVisible: !this.state.optionsVisible });
  };

  handleSelect = (name, value) => {
    const {handleChange} = this.props;

    if (handleChange) {
      handleChange({
        target: {name, value, type: 'select'}
      });
    }

    this.handleOptionsToggle();
  };

  render() {
    const {
      name,
      selectedOption,
      options,
      label,
      required,
      click,
      noToggle,
      selectedFieldStyle,
      toggleStyle,
      optionContainerStyle,
      optionStyle,
      optionHoverColor,
      noDot,
      ...props
    } = this.props;

    return (
      <Container width={this.props.width} onClick={click && click} shadow={this.props.shadow} {...props}>
        <Label>{label} <Required>{required && '*'}</Required></Label>
        <Div
          onBlur={() => this.setState({optionsVisible: false})}
          onFocus={!noToggle ? () => this.handleOptionsToggle(): null}
          tabIndex="0"
        >
          <Field
            fontSize={this.props.fontSize}
            style={{...selectedFieldStyle}}
            noBorder={this.props.noBorder}
            name={name}
            onBlur={() => this.setState({optionsVisible: false})}
            onFocus={!noToggle ? () => this.handleOptionsToggle(): null}
          >
            {selectedOption && selectedOption.icon && <Icon src={selectedOption.icon} alt='' />}
            <Shorthand fontSize={this.props.fontSize}>{selectedOption && selectedOption.useShorthand ? selectedOption.shorthand.toUpperCase() : selectedOption.name}</Shorthand>
            <OptionsToggle style={{...toggleStyle}}>
              <i className="fa fa-sort-down"></i>
            </OptionsToggle>
          </Field>
        </Div>
        <OptionContainer visible={this.state.optionsVisible} style={{...optionContainerStyle}}>
          {optionItems(options, name, this.handleSelect, selectedOption.id, noDot, optionHoverColor, optionStyle, this.props.fontSize)}
        </OptionContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: ${props => props.width ? 'inline-block' : 'block'};
  min-width: ${props => props.width || '100%'};
  max-width: ${props => props.width || '100%'};
  margin: 0;
  padding: 0;
  float: ${props => props.float || 'none'};
  text-align: left;
  box-shadow: ${props => props.shadow || 'none'};
`;

const Label = styled.label`
  display: block;
  margin: 0.25rem 0;
  font-size: 14px;
  color: ${colors.TEXT_INPUT};
`;

const Div = styled.div`
  outline: none;
`;

const Required = styled.span`
  color: ${colors.BUTTON_BLUE};
`;

const Field = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%; max-width: 100%;
  min-height: ${props => props.height || '40px'};
  max-height: ${props => props.height || '40px'};
  margin: 0.5rem 0;
  padding-left: 0.5em;
  padding-right: 1.5em;
  border: ${props => props.noBorder ? '0px': '1px'} solid ${colors.TEXT_INPUT}; border-radius: 3px;
  color: transparent; text-shadow: 0 0 0 black;
  background-color: white;
  outline: none;
  &:focus { outline: none; };
  cursor: pointer;
  @media screen and (max-width: ${bp.phone}) {
    font-size: 16px;
  }
  @media screen and (min-width: ${bp.phone}) {
    font-size: ${props => props.fontSize || '1em'};
  }
`;
Field.defaultProps = {
  type: 'text',
};

const OptionsToggle = styled.span`
  position: absolute; right: 0.5em;
  z-index: 1;
  padding-bottom: 0.33em;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${bp.phone}) {
    display: none;
  }
`;

const OptionContainer = styled.div`
  position: absolute; bottom: 0; left: 0; right: 0;
  transform: translateY(100%);
  overflow: hidden;
  display: ${props => props.visible ? 'block' : 'none'};
  border: 1px solid ${colors.LINE}; border-radius: 5px;
  background-color: white;
  z-index: 2;
  max-height: 250px;
  overflow-y: auto;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100%; max-width: 100%;
  min-height: 50px; max-height: 50px;
  padding: 0 0.5em;
  border-bottom: 1px solid ${colors.LINE};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.hoverColor || colors.BG};
  }
  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

const Shorthand = styled.span`
  @media screen and (max-width: ${bp.phone}) {
    display: none;
    font-size: ${props => props.fontSize || '0.5em'};
  }
  @media screen and (min-width: ${bp.phone}) {
    font-size: ${props => props.fontSize || '1em'};
  }
`;

const Icon = styled.img`
  margin-right: 0.5em;
  @media screen and (max-width: ${bp.phone}) {

  }
  @media screen and (min-width: ${bp.phone}) {
    width: 1.5em;
  }
`;

export default SelectField;
