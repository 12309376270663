import React from 'react';
import {compose} from 'redux';

import {getManagersList} from '../../api';
import {SelectField} from '../common/Form';
import {connect} from "react-redux";

function LocationOwnerSelect({
  value,
  onChange,
  authorization,
  role,
  defaultValue,
  ...props
}) {
  const [ownersOptions, setOwnersOptions] = React.useState([]);

  React.useEffect(() => {
    const setOwnerOptionsFromData = (data) => {
      const options = data.map(manager => ({
        id: manager.id,
        name: [manager.first_name, manager.last_name].join(' ') || manager.email,
      }))

      setOwnersOptions(options);
    }

    getManagersList({authorization: authorization})
      .then(response => response.json())
      .then(data => setOwnerOptionsFromData(data));
  }, [])

  const selectedValue = value || defaultValue;

  if (role === 'administrator') {
    return (
      <SelectField
        noDot
        label="Владелец"
        optionHoverColor="#E1EEFF"
        name="owner_id"
        selectedOption={{...ownersOptions.find(option => option.id === selectedValue)}}
        options={ownersOptions}
        selectedFieldStyle={{marginTop: 0}}
        handleChange={onChange}
        {...props}
      />
    )
  }

  return null;
}

const mapStateToProps = ({manager}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  role: manager.data?.role,
  defaultValue: manager.data?.id,
});

export default compose(
  connect(mapStateToProps),
)(LocationOwnerSelect);
