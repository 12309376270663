import {getJSON as get} from '../../api';
import {GET_LOCATIONS_START, GET_LOCATIONS_START_MORE, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE} from './types';

const getLocationsStart = () => ({
  type: GET_LOCATIONS_START,
});

const getLocationsMoreStart = () => ({
  type: GET_LOCATIONS_START_MORE,
});

const getLocationsSuccess = (data, params, loadMore) => ({
  type: GET_LOCATIONS_SUCCESS,
  data,
  params,
  loadMore,
});

const getLocationsFailure = error => ({
  type: GET_LOCATIONS_FAILURE,
  error,
});

const defaultLimit = 500;

const getLocations = (
  authorization,
  params,
  callback,
  excludeLoading = false,
  loadMore = false
) => {
  if (!params.limit) {
    params.limit = defaultLimit;
  }

  return dispatch => {

    if (!excludeLoading) {
      dispatch(getLocationsStart());
    }

    if (loadMore) {
      dispatch(getLocationsMoreStart());
    }

    get('locations', {authorization}, params)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getLocationsSuccess(data, params, loadMore));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(getLocationsFailure(error));
      });
  };
};

export default getLocations;
