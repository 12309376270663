import React from 'react';
import {connect} from 'react-redux';
import getLocations from "../../redux/actions/getLocations";
import Button from "../common/Button";
import RefreshIcon from "../icons/RefreshIcon";

function RefreshButton({getLocations, authorization, params }) {
  const onClick = () => {
    getLocations(
      authorization,
      {
        mode: 'full',
        search: params?.search || '',
      },
    );
  }

  return (
    <Button
      theme="blue"
      minWidth="none"
      onClick={onClick}
    >
      <RefreshIcon />
    </Button>
  )
}

const mapStateToProps = ({ manager, locations: { params } }) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  params,
});

const mapDispatchToProps = dispatch => ({
  getLocations: (...params) => dispatch(getLocations(...params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefreshButton);
