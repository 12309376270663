import {GET_LOCATIONS_START, GET_LOCATIONS_START_MORE, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  loadingMore: false,
  lastLoad: false,
  error: null,
};

const locations = (state = initialState, action) => {
  switch (action.type) {
    // locations
    case GET_LOCATIONS_START:
      return { ...state, loading: true, loadingMore: false };
    case GET_LOCATIONS_START_MORE:
      return { ...state, loading: false, loadingMore: true };
    case GET_LOCATIONS_SUCCESS: {
      const findLocation = (id) => state.data.find(location => location.id === id);
      const mergedData = action.data.map((location) => ({...findLocation(location.id), ...location}));

      return {
        ...state,
        params: action.params,
        data: action.loadMore ? [ ...state.data, ...mergedData ] : action.data,
        lastLoad: action.data.length < action.params.limit,
        loading: false,
        error: null,
        loadingMore: false,
      };
    }
    case GET_LOCATIONS_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};

export default locations;
